import React from 'react';
import Select, { ActionMeta, components, OptionsType } from 'react-select';
import { ContainerProps } from 'react-select/src/components/containers'
import { MenuList } from 'react-select/src/components/Menu'

export const NAME = 'dfbDropdown'
export const CLASS = 'dfb-Dropdown'

export const ClassName = {
  ROOT: CLASS,
  SELECT: `${CLASS}-select`,
  LABEL: `${CLASS}-label`,
  COMBOBOX: `${CLASS}-combobox`,
  VALUE: `${CLASS}-value`,
  TOGGLE: `${CLASS}-toggle`,
  LISTBOX: `${CLASS}-listbox`,
  ITEM: `${CLASS}-item`,
  OPTION: `${CLASS}-option`,
  OPTIONLABEL: `${CLASS}-optionLabel`,
  INPUT: `${CLASS}-input`
}


export const States = {
  OPEN: 'is-open',
  INVALID: 'is-invalid'
}

interface OptionType {
  value: any;
  label: any;
}

interface MultipleDropdownProps {
  options: OptionType[];
  onChangeCallback: (selectedOptions: OptionsType<OptionType>) => void;
  errorMessage?: string;
  isDisabled?: boolean;
  placeholder?: string;
}

const CustomSelectContainer = (props: ContainerProps<any, any>, errorMessage?: string) => {
  return (
    <div
      // workaround for bug in dfb-ui
      data-default-label=''
      className={`dfb-Dropdown${props.selectProps.menuIsOpen ? ` ${States.OPEN}` : ''}${!errorMessage ? '' : ` ${States.INVALID}`
      }${!props.isDisabled ? '' : ` is-disabled`} multiple-dropdown`}>
      <components.SelectContainer {...props}>{props.children}</components.SelectContainer>
      {errorMessage && <p className='dfb-Paragraph dfb-Paragraph--small dfb-Dropdown-errorMessage'>{errorMessage}</p>}
    </div>
  )
}

const CustomMenuList: typeof MenuList = props => {
  return (
    <components.MenuList {...props} className={ClassName.LISTBOX}>
      <ul>{props.children}</ul>
    </components.MenuList>
  )
}

const Option = (props: any) => (
  <div>
    <components.Option {...props}>
      <label>
        <input type='checkbox' defaultChecked={props.isSelected}/>{' '}
        {props.label}
      </label>
    </components.Option>
  </div>
);

const MultiValue = (props: any) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const MultipleDropdown: React.FC<MultipleDropdownProps> = (props: any) => {
  const handleChange = (value: OptionsType<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    props.onChangeCallback(value);
  };

  return (
    <Select
      isMulti
      components={{
        Option, MultiValue,
        SelectContainer: selectContainerProps => CustomSelectContainer(selectContainerProps, props.errorMessage),
        MenuList: CustomMenuList,
      }}
      options={props.options}
      hideSelectedOptions={false}
      backspaceRemovesValue={false}
      onChange={handleChange}
      errorMessage={props.errorMessage}
      isDisabled={props.isDisabled}
      placeholder={props.placeholder}
    />
  );
};

export default MultipleDropdown;
