import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNav } from '../../hooks/useNav'
import SectionHeader from '../atom/section-header'
import VideoGallery from '../element/video-gallery'

const SectionVideoGallery = ({ id }: { id: string }) => {
  const { t } = useTranslation();
  const ref = useNav(id);

  return (
    <div id={id} ref={ref}>
      <div className='section section-video-gallery'>
        <div className='grid-wrapper'>
          <div className='grid-container'>
            <div className='grid-cell--half grid-offset-pre--one'>
              <SectionHeader
                title={t('video-gallery.intro.title') as string}
                subtitle={t('video-gallery.intro.headline') as string}
                text={t('video-gallery.intro.copy') as string}
                extendedText={t('video-gallery.intro.copy-extended') as string}
                // externalLink={t('video-gallery.intro.link-href') as string}
                linkLabel={t('video-gallery.intro.link-label') as string}
                hasHalfWidthAnimation={false}
              />
            </div>
          </div>
        </div>
        <div className="grid-wrapper">
          <div className="grid-container">
            <div className="section-video-gallery__video grid-offset-pre--two grid-offset-post--two">
              <VideoGallery />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default SectionVideoGallery
