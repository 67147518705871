import React, { Suspense, useState } from 'react'
import './App.scss'
import { Spinner } from '@dfb/dfb-ui-react/brands/dachmarke/index'

import NavigationProvider from './context/NavigationContext'
import { useScrollDirection } from './hooks/useScrollDirection'
import Header from './components/module/header'
import Hero from './components/module/hero'
import SectionTaktik from './components/module/section-taktik'
import SectionAnpfiff from './components/module/section-anpfiff'
import SectionSpiel from './components/module/section-spiel'
import SectionVereinskasse from './components/module/section-vereinskasse'
import SectionTeamplayer from './components/module/section-teamplayer'
import Footer from './components/element/footer'
import { ModalTeaser } from './components/element/modal-teaser'
import { ModalFormular } from './components/element/modal-formular'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useIntersectionNavigation } from './hooks/useIntersectionNavigation'
import SectionAnalyse from './components/module/section-analyse';
import SectionVideoGallery from './components/module/section-video-gallery'

export type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
}


const Page = () => {
  const isExitTeaser = localStorage.getItem('exit-teaser') !== 'hide'
  const [teaserVisible, setTeaserVisible] = useState(isExitTeaser);
  const [formularVisible, setFormularVisible] = useState(false);
  const { lastScrollDirection } = useScrollDirection()
  useIntersectionNavigation()
  gsap.registerPlugin(ScrollTrigger);

  const closeTeaserModal = () => {
    setTeaserVisible(false)
    localStorage.setItem('exit-teaser', 'hide');
  }

  const openFormularModal = () => {
    setTeaserVisible(false);
    setFormularVisible(true);
  };

  const closeFormularModal = () => {
    setFormularVisible(false);
  };

  return (
    <div
      className={`container ${lastScrollDirection ? 'scrolling-'.concat(lastScrollDirection?.toLowerCase()) : ''}`}>
      <Header />
      <Hero id='hero' next='anpfiff' />
      <SectionAnpfiff id='anpfiff' />
      <SectionTaktik id='taktik' />
      <SectionAnalyse id='analyse' />
      <SectionSpiel id='spielt-mit' />
      <SectionVereinskasse id='vereinskasse' onFormularOpen={openFormularModal} />
      <SectionVideoGallery id='training' />
      <SectionTeamplayer id='teamplayer' />
      <Footer id='footer' />
      <ModalTeaser
        visible={teaserVisible}
        onClose={closeTeaserModal}
        onFormularOpen={openFormularModal}
      />
      <ModalFormular
        visible={formularVisible}
        onClose={closeFormularModal}
      />
    </div>
  )
}

const Loader = () => {
  return (
    <div className='loader'>
      <Spinner showLoader></Spinner>
    </div>
  )
}

// here app catches the suspense from page in case translations are not yet loaded
//delete me : trigger rebuild comment
const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <NavigationProvider>
        <Page />
      </NavigationProvider>
    </Suspense>
  )
}
export default App
