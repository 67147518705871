import React, { useState } from 'react'
import { Anchor, Card, CardContent, Modal, ModalContent, Button } from '@dfb/dfb-ui-react/brands/dachmarke'
import { ReactComponent as InfoLogo } from '../../assets/images/logo-info.svg'

type DataSectionCard = {
  teaser?: string
  titel?: string
  subtitel?: string
  description?: string
  modal?: any
  link?: string
  linkLabel?: string
  isTippModalVisible?: () => void
  onClick?: () => void | undefined
  onFormularOpen?: () => void
}

const SectionCard = (props: DataSectionCard) => {
  const [isTippModalVisible, setIsTippModalVisible] = useState(false)

  return (
    <>
      <div className='section-card'>
        <Card>
          <CardContent>
            {props.teaser && <p className='section-card__teaser'>{props.teaser}</p>}
            {props.titel && <h5 className='section-card__titel'>{props.titel}</h5>}
            {props.subtitel && <h5 className='section-card__subtitel'>{props.subtitel}</h5>}
            {props.description && <p className='section-card__description'>{props.description}</p>}
            {props.link && (
              <a className={`dfb-Anchor dfb-Anchor--secondary`} href={props.link} target='_blank' rel='noreferrer'>
                <span className='dfb-Anchor-label'>{props.linkLabel}</span>
              </a>
            )}
            {props.onClick && <Anchor type={'secondary'} label={props.linkLabel} onClick={props.onClick} />}
          </CardContent>
          {props.subtitel && <div className='section-card__tipp' onClick={() => { setIsTippModalVisible(true) }}>
            <p><b>Vereins-Tipp</b></p>
            <InfoLogo />
          </div>}
        </Card>
      </div>
      {isTippModalVisible && (
        <div className='section-card__tipp-modal'>
          <Modal
            visible={isTippModalVisible}
            fullSize={false}
            close={() => { setIsTippModalVisible(false) }}>
            <ModalContent>
              <h4>Dieser Tipp kommt von {props.subtitel}</h4>
              <p>Kennt Ihr noch weitere Fördermöglichkeiten? <br />Dann öffnet das Formular und teilt euer Wissen!</p>
              <Button label={'ZUM FORMULAR'} onClick={props.onFormularOpen ? props.onFormularOpen : () => { }}
                type={'primary'} />
            </ModalContent>
          </Modal>
        </div>
      )}
    </>
  )
}

export default SectionCard
