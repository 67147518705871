import React, { useState, useEffect } from 'react'
import { Modal, ModalContent, Button } from '@dfb/dfb-ui-react/brands/dachmarke'
import { useTranslation } from 'react-i18next'

interface ModalTeaserProps {
  visible: boolean;
  onClose: () => void;
  onFormularOpen: () => void;
}

export const ModalTeaser: React.FC<ModalTeaserProps> = (props: any) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(props.visible);

  useEffect(() => {
    setIsModalVisible(props.visible);
  }, [props.visible]);

  const handleButtonClick = () => {
    setIsModalVisible(false);
    localStorage.setItem('exit-teaser', 'hide');
    props.onClose();
    props.onFormularOpen();
  };

  return (
    <div className='modal-teaser'>
      <Modal visible={isModalVisible} fullSize={props.fullSize} close={props.onClose}>
        <ModalContent className='modal-teaser__container'>
          <div className='modal-teaser__content'>
            <h4 className='modal-teaser__content-title h4'>{t('modal-teaser.title')}</h4>
            <p>{t('modal-teaser.text')}</p>
          </div>
        </ModalContent>
        <Button
          label="ZUM FORMULAR"
          className=""
          type="primary"
          onClick={handleButtonClick} />
      </Modal>
    </div>
  )
}
